<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 21:08:06
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-11-23 18:21:54
-->
<template>
  <div class="install-details">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{
          path:
            dtype != 1
              ? type == 6
                ? '/personal-center/install/evaluation'
                : list[type - 1].path
              : '/personal-center/install/reorder'
        }"
      >
        订单数据统计
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path:
            dtype == 1
              ? `/personal-center/install/back-details?type=${this.type}&oid=${this.oid}`
              : `/personal-center/install/details?type=${this.type}&oid=${this.oid}`
        }"
        >订单详情</el-breadcrumb-item
      >
      <el-breadcrumb-item>配件申请详情</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 配件单详情 -->
    <div class="accessories_details evaluation" id="install-details">
      <div class="logistics_info">
        <div class="status_list">
          <div class="status" v-for="(item, i) in list1" :key="i">
            <div class="item">
              <div class="text">
                <img :src="item.isArrive ? item.src_sel : item.src" alt="" />
                <p class="title">{{ item.title }}</p>
                <p class="date">{{ item.time }}</p>
              </div>
              <div>
                <img
                  :src="item.isArrive ? item.arrows_sel : item.arrows"
                  alt=""
                  v-show="i < list1.length - 1"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="not" v-if="accessories_info.status == 3">
          审核不通过原因
        </div>
        <el-input
          v-if="accessories_info.status == 3"
          v-model="accessories_info.aduitInfo"
          class="form-textarea"
          type="textarea"
          :rows="5"
          disabled
          placeholder=""
        ></el-input>
        <el-form
          ref="form"
          :model="accessories_info"
          class="totality"
          v-if="accessories_info.status == 2"
        >
          <el-form-item label="物流公司:">
            <el-input
              v-model="accessories_info.logisticsName"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="物流单号:">
            <el-input
              v-model="accessories_info.logisticsNum"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="第三方配件单号:">
            <el-input
              v-model="accessories_info.threeNum"
              disabled="disabled"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="remarks" v-if="accessories_info.status == 2">返件备注:</div>
        <el-input
          v-if="accessories_info.status == 2"
          disabled="disabled"
          class="text"
          resize="none"
          type="textarea"
          size="medium"
          v-model="accessories_info.remarke"
          placeholder="请输入返件备注"
        >
        </el-input>
        <div>
          <div class="remarks">
            申请凭证:
          </div>
          <img
            v-for="(item, i) in accessories_info.accessoriesImg"
            :key="i"
            :src="item.showPath"
            alt=""
            style="
            width: 120px;
            height: 120px;
            border-radius: 10px;
            margin-right: 20px;
          "
          />
        </div>
        <div class="remarks" v-if="accessories_info.status == 2">物流信息:</div>

        <el-timeline :reverse="reverse" v-if="accessories_info.status == 2">
          <el-timeline-item
            v-for="(activity, index) in getLogistics"
            :key="index"
            :timestamp="activity.time"
          >
            <p class="date">{{ activity.time }}</p>
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </div>

      <!-- 返件 -->
      <div
        class="information"
        v-if="
          this.accessories_info.oldReturn == 1 && accessories_info.status != 3
        "
      >
        <div class="rise">返件信息</div>
        <el-form
          class="logistics_form"
          ref="logistics_form"
          inline
          label-position="left"
          label-width="120px"
          :model="returnData"
          :rules="return_rules"
        >
          <el-form-item label="返件地址:" style="width: 100%">
            <el-input
              disabled
              v-model="returnData.returnAddress"
              style="width: 676px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="返件要求:" style="width: 100%">
            <el-input
              disabled
              resize="none"
              v-model="returnData.accessoriesDetail"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="物流公司:"
            style="width: 38%;"
            prop="logisticsName"
          >
            <el-input
              v-model="returnData.logisticsName"
              :disabled="returnDisal"
              style="width: 250px;"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="物流单号:"
            style="width: 36%"
            prop="logisticsNum"
          >
            <el-input
              v-model="returnData.logisticsNum"
              :disabled="returnDisal"
              style="width: 250px;"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="第三方配件单号:">
            <el-input v-model="returnData.threeNum"></el-input>
          </el-form-item> -->
          <el-form-item label="发件备注:" style="width: 98%">
            <el-input
              resize="none"
              v-model="returnData.remarke"
              type="textarea"
              placeholder="请输入发件备注"
              :disabled="returnDisal"
            ></el-input>
          </el-form-item>
          <el-form-item label="凭证:" prop="accessoriesImg">
            <div v-show="returnDisal">
              <img
                v-for="(item, i) in returnData.accessoriesImg"
                :key="i"
                :src="item.showPath"
                style="height: 100px;margin-right: 10px;"
              />
            </div>
            <el-upload
              action="*"
              :http-request="uploadImg_oldreturn"
              list-type="picture-card"
              :on-remove="handleAvatarRemove_oldreturn"
              :before-upload="beforeAvatarUpload"
              accept="image/*"
              :file-list="filelist"
              v-show="!returnDisal"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>

        <div class="button_btn" v-show="!returnDisal">
          <el-button type="primary" @click="save_return_form"> 提交 </el-button>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("配件数量不能为空"));
      } else {
        if (!isNaN(value)) {
          callback();
        } else {
          return callback(new Error("配件数量必须为数字"));
        }
      }
    };
    return {
      title: "同意",
      oid: 0,
      type: 1,
      list: [
        {
          path: "/personal-center/install/signit"
        },
        {
          path: "/personal-center/install/inservice"
        },
        {
          path: "/personal-center/install/appointment"
        },
        {
          path: "/personal-center/install/complete"
        },
        {
          path: "/personal-center/install/settlement"
        }
      ],
      list1: [
        {
          src: require("@/assets/img/install/img-install-details-2.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-2.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "提交配件申请",
          time: "",
          isArrive: true,
          path: "/personal-center/install/inservice"
        },
        {
          src: require("@/assets/img/install/img-install-details-3.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-3.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "申请审核中",
          time: "",
          isArrive: false,
          path: "/personal-center/install/appointment"
        },
        {
          src: require("@/assets/img/install/img-install-details-4.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-4.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "同意",
          time: "",
          isArrive: false,
          path: "/personal-center/install/complete"
        }
      ],
      getLogistics: [],
      accessories_info: {
        status: 1
      },
      reverse: true,
      state: "",
      filelist: [],
      // 返件信息
      returnData: {
        returnAddress: "",
        accessoriesDetail: "",
        logisticsName: "",
        logisticsNum: "",
        remarke: "",
        accessoriesImg: []
      },
      returnDisal: false,

      //  返件信息
      rules: {
        accessoriesName: [
          { required: true, message: "配件名称不能为空", trigger: "blur" }
        ],
        accessoriesNum: [{ validator: checkNum, trigger: "blur" }],
        accessoriesImg: [
          { required: true, message: "请您上传凭证", trigger: "blur" }
        ]
      },
      return_rules: {
        logisticsName: [
          { required: true, message: "物流名称不能为空", trigger: "blur" }
        ],
        logisticsNum: [
          { required: true, message: "物流单号不能为空", trigger: "blur" }
        ],
        accessoriesImg: [
          { required: true, message: "请您上传凭证", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },

  mounted() {
    let type = this.$route.query.type;
    this.dtype = this.$route.query.dtype;
    this.oid = this.$route.query.oid;

    this.type = Number(type);
    this.id = this.$route.query.id;
    this.accessoriesOrderDetail();
  },

  methods: {
    accessoriesOrderDetail() {
      this.$api
        .accessoriesOrderDetail({
          id: this.id
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            console.log("data", data);
            this.accessories_info = data;
            // // this.returnData
            // console.log(this.accessories_info.accessoriesImg);
            if (this.accessories_info.logisticsNum) {
              this.query_getLogistics();
            }

            this.returnData.number = this.accessories_info.number;
            this.returnData.id = this.accessories_info.id;
            // this.accessories_info.accessoriesImg = this.accessories_info.accessoriesImg.split(
            //   ","
            // );
            let accessoriesImgs = [];

            if (this.accessories_info.accessoriesImg) {
              let accessoriesImgStr = this.accessories_info.accessoriesImg.split(
                ","
              );

              accessoriesImgStr.map(item => {
                accessoriesImgs.push({
                  name: "none",
                  showPath: this.$utils.picturePrefix(item),
                  path: item
                });
              });
            }
            this.accessories_info.accessoriesImg = accessoriesImgs;
            this.returnData.returnAddress =
              this.accessories_info.returnAddress &&
              this.accessories_info.returnAddress.address;
            this.returnData.accessoriesDetail = this.accessories_info.accessoriesDetail;
            this.list1[0].time = this.accessories_info.applyTime;
            if (this.accessories_info.status == 1) {
              this.list1[1].isArrive = true;
              this.list1[1].time = this.accessories_info.applyTime;
            } else if (
              this.accessories_info.status == 2 ||
              this.accessories_info.status == 3
            ) {
              this.list1[1].isArrive = true;
              this.list1[1].time = this.accessories_info.applyTime;
              this.list1[2].isArrive = true;
              this.list1[2].time = this.accessories_info.aduitTime;
            }
            if (this.accessories_info.status == 3) {
              this.list1[2].title = "不同意";
            }
            let accessoriesImg = [];
            if (this.accessories_info.accessoriesLogisticsImg) {
              let accessoriesLogisticsImg = this.accessories_info.accessoriesLogisticsImg.split(
                ","
              );
              accessoriesLogisticsImg.map(item => {
                accessoriesImg.push({
                  name: "none",
                  showPath: this.$utils.picturePrefix(item),
                  path: item
                });
              });
            }

            this.returnData.logisticsName = this.accessories_info.accessoriesLogisticsName;
            this.returnData.logisticsNum = this.accessories_info.accessoriesLogisticsNum;
            this.returnData.remarke = this.accessories_info.accessoriesLogisticsRemarke;
            this.returnData.accessoriesImg = accessoriesImg;
            this.returnDisal =
              this.accessories_info.accessoriesLogisticsNum != "" &&
              this.accessories_info.accessoriesLogisticsNum != null;
          }
        });
    },

    /**
     *  返件上传图片
     */
    uploadImg_oldreturn(param) {
      let file = param.file;
      let form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .uploadImg(form)
        .then(res => {
          this.returnData.accessoriesImg.push({
            name: file.name,
            showPath: this.$utils.picturePrefix(res.data.filePath),
            path: res.data.filePath
          });
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     *返件 删除图片
     */
    handleAvatarRemove_oldreturn(file) {
      this.returnData.accessoriesImg = this.accessories.accessoriesImg.filter(
        e => {
          return e.name != file.name;
        }
      );
    },
    /**
     * 上传前图片判断
     */
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },

    /**
     * 获取物流信息
     */
    query_getLogistics() {
      let logisticsNum = this.accessories_info.logisticsNum;
      if (logisticsNum.indexOf("SF") == 0) {
        logisticsNum +=
          ":" + this.accessories_info.logisticsPhone.substring(7, 11);
      }
      this.$api
        .getLogistics({
          logisticsNumber: logisticsNum
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            this.getLogistics = data;
          }
        });
    },

    /**
     *   提交返件表单
     */
    save_return_form() {
      this.$refs.logistics_form.validate(valid => {
        if (valid) {
          let img = [];
          if (Array.isArray(this.returnData.accessoriesImg)) {
            this.returnData.accessoriesImg.forEach(e => {
              img.push(e.path);
            });

            this.returnData.accessoriesImg = img.join(",");
          }

          this.$api
            .installationOrderReturn({
              number: this.returnData.number,
              id: this.returnData.id,
              // 返件物流单号
              accessoriesLogisticsNum: this.returnData.logisticsNum,
              //  返件物流名称
              accessoriesLogisticsName: this.returnData.logisticsName,
              //  返件物流凭证
              accessoriesLogisticsImg: this.returnData.accessoriesImg,
              // 返件物流备注
              accessoriesLogisticsRemarke: this.returnData.remarke
            })
            .then(res => {
              let { code, msg } = res;
              if (code == 200) {
                this.$message({
                  type: "success",
                  message: msg,
                  duration: 2000
                });
                this.returnDisal = true;
                this.accessoriesOrderDetail();
              } else {
                this.$message({
                  type: "error",
                  message: msg,
                  duration: 2000
                });
              }
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: "处理出错,请稍后再试!",
                duration: 2000
              });
            });
        }
      });
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
